import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import AdEdit, { AdsList } from './Components/ads/ads';
import dataProvider from './api/DataProvider';
import authProvider from './authProvider';

const App = () => (
  <Admin authProvider={authProvider} dataProvider={dataProvider}>
    <Resource name="ads" list={AdsList} edit={AdEdit} />
  </Admin>
);

export default App;
