import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { apiUrl } from './apiConf'
const httpClient = fetchUtils.fetchJson;


const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { order } = params.sort;
    const query = {
      page: page - 1,
      size: perPage,
      sortField: 'pubDate',
      sortOrder: order,
      statuses: params.filter.statuses,
    };
    const url = `${apiUrl}/rest/ads?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.content,
        total: json.totalElements,
        pagination: { page: 1, perPage: 5 },
      };
    });
  },

  getOne: (resource, params) => {
    return httpClient(`${apiUrl}/rest/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json,
      })
    );
  },
};

export default dataProvider;
