import * as React from 'react';
import { Button, useRecordContext, useRedirect, useNotify } from 'react-admin';
import './Button.css';
import { ReactComponent as Success } from '../images/success.svg';
import { ReactComponent as Decline } from '../images/cancel.svg';
import { apiUrl } from '../api/apiConf';
const statusObject = {
  PUBLISHED: true,
  UNAPPROVED: false,
};
export const CustomButton = ({ status }) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);
    const adStatus = status;
    const token = localStorage.getItem('auth');
    fetch(`${apiUrl}/rest/ads/${record.id}/updateStatus?adStatus=${adStatus}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        notify(`Объявление ${statusObject[status] ? 'одобрено' : 'отклонено'}`);
        redirect('/ads');
      })
      .catch((e) => {
        notify(
          `Error: Ошибка объявление не ${statusObject[status] ? 'одобрено' : 'отклонено'
          }`,
          { type: 'warning' }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      className={statusObject[status] ? 'success' : 'error'}
      label={statusObject[status] ? 'Одобрить' : 'Отклонить'}
      onClick={handleClick}
      disabled={loading}
      color={statusObject[status] ? 'success' : 'error'}
    >
      {statusObject[status] ? (
        <Success width="30px" />
      ) : (
        <Decline width="30px" />
      )}
    </Button>
  );
};
