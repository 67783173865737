import {
  List,
  Datagrid,
  Button,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  useRecordContext,
  Toolbar,
  SelectInput,
  useRedirect,
  TopToolbar,
  FunctionField,
} from 'react-admin';
import closeIcon from '../../images/close.png';
import { CustomButton } from '../Button';
import Images from '../Images/Images';
import { ReactComponent as BackIcon } from '../../images/backIcon.svg';
import './ads.css';

import { Pagination } from 'react-admin';

const STATUSES = {
  MODERATED: 'НОВОЕ',
  PUBLISHED: 'ОПУБЛИКОВАНО',
  UNAPPROVED: 'ОТКЛОНЕНО',
  COMPLETED: 'ПРОДАНО',
  CANCELED: 'СНЯТО ПОЛЬЗОВАТЕЛЕМ',
  DRAFT: 'ЧЕРНОВИК',
};
const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
);

const adsFilters = [
  <SelectInput
    source="statuses"
    emptyText={'НОВОЕ'}
    emptyValue={'MODERATED'}
    choices={[
      { id: 'PUBLISHED', name: 'ОПУБЛИКОВАНО' },
      { id: 'UNAPPROVED', name: 'ОТКЛОНЕНО' },
      { id: 'COMPLETED', name: 'ПРОДАНО' },
      { id: 'CANCELED', name: 'СНЯТО ПОЛЬЗОВАТЕЛЕМ' },
      { id: 'DRAFT', name: 'ЧЕРНОВИК' },
    ]}
    alwaysOn
  />,
];

export const AdsList = () => (
  <List exporter={false} filters={adsFilters} pagination={<PostPagination />}>
    <Datagrid bulkActionButtons={false}>
      <TextField sortable={false} label="Название" source="name" />
      <TextField sortable={false} label="Описание" source="description" />
      <FunctionField
        sortable={true}
        label="Дата публикации"
        source="pubDate"
        render={(record) => {
          const pubDate = new Date(record.pubDate);
          const date = pubDate.getDate();
          const month = pubDate.getMonth() + 1;
          const year = pubDate.getFullYear();
          return <span>{`${date}-${month}-${year}`}</span>;
        }}
      />
      <FunctionField
        sortable={false}
        label="Статус"
        source="status"
        render={(record) => {
          return <span>{STATUSES[record.status]}</span>;
        }}
      />
      <EditButton />
    </Datagrid>
  </List>
);

const AdEditToolbar = (props) => {
  const redirect = useRedirect();
  const handleClick = () => {
    redirect('/ads');
  };
  return (
    <Toolbar
      {...props}
      sx={{
        '&.RaToolbar-mobileToolbar': {
          disableRipple: true,
        },
      }}
    >
      <CustomButton status="PUBLISHED" />
      <CustomButton status="UNAPPROVED" />
      <Button
        className="warning"
        label="Назад"
        onClick={handleClick}
        color="warning"
      >
        <BackIcon width="30px" />
      </Button>
    </Toolbar>
  );
};

const AdTitle = () => {
  const record = useRecordContext();
  return <span> {record ? `"${record.name}"` : ''}</span>;
};

const AdsEditActions = () => {
  const redirect = useRedirect();
  const handleClick = () => {
    redirect('/ads');
  };
  return (
    <TopToolbar>
      <img
        className="closeButton"
        width={'15px'}
        onClick={handleClick}
        src={closeIcon}
        alt="close"
      />
    </TopToolbar>
  );
};

const AdEdit = () => (
  <Edit className="edit" actions={<AdsEditActions />} title={<AdTitle />}>
    <SimpleForm toolbar={<AdEditToolbar />}>
      <TextInput className="half" disabled source="id" />
      <TextInput className="half" disabled source="name" />
      <TextInput className="half" multiline disabled source="description" />
      <TextInput className="half" disabled source="status" />
      <Images />
    </SimpleForm>
  </Edit>
);

export default AdEdit;
