import React, { useEffect, useState } from 'react';
import { useEditContext } from 'react-admin';
import { Gallery } from '../Gallery/Gallery';
import { apiUrl } from '../../api/apiConf';
import './Images.css';
import PopupPortal from '../Portal';

const Images = () => {
  const [imagesList, setImagesList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { record } = useEditContext();
  const { images } = record;
  useEffect(() => {
    const myFetch = async () => {
      const res = [];
      for (let i = 0; i < images.length; i++) {
        const response = await fetch(`${apiUrl}/rest/images/${images[i]}/image.jpg`);
        const imageBlob = await response.blob();
        const imageObjectURL = window.URL.createObjectURL(imageBlob);
        res.push(imageObjectURL);
      }
      setImagesList(res);
    };
    myFetch();
  }, [images]);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div onClick={handleClick}>
        <div>Фото:</div>
        {imagesList?.map((image, index) => {
          return (
            <img
              key={`${image}-${index}`}
              width={100}
              src={image}
              alt={`${image}-${index}`}
            />
          );
        })}
        {!imagesList.length && '  Фото отсутствует'}
      </div>
      <PopupPortal
        children={
          <div className={`modal-wrapper ${isOpen ? 'open' : 'close'}`}>
            <div className="modal-window">
              <Gallery images={imagesList} />
            </div>
            <div onClick={handleClose} className="overlay"></div>
          </div>
        }
        mountNode={document.body}
      />
    </>
  );
};

export default Images;
