import React from 'react';
import Carousel from 'better-react-carousel';
import './Gallery.css';
export const Gallery = ({ images }) => {
  return (
    <Carousel
      containerClassName="carousel"
      cols={1}
      rows={1}
      gap={10}
      hideArrow={images.length < 1}
    >
      {images?.map((img, index) => {
        return (
          <Carousel.Item key={`${img}`}>
            <img height="100%" width="100%" src={img} />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};
